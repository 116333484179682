'use strict';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Sliders = {
    init: function () {
        this.initializeSlider('.f-news__slider', this.initSliderNews);
        this.initializeSlider('.f-downloads__slider', this.initSliderDownloads);
        this.initializeSlider('.f-rubrics__slider', this.initSliderRubrics);
        this.initializeSlider('.f-multimedias__slider', this.initSliderMultimedias);
        this.initializeSlider('.f-resources__slider', this.initSliderResources);
        this.initializeSlider('.f-toolsbox__slider', this.initSliderToolsbox);
    },

    initializeSlider: function (selector, initFunction) {
        const slider = document.querySelectorAll(selector);
        if (slider.length > 0) {
            initFunction.call(this);
            if (selector === '.f-news__slider' || selector === '.f-downloads__slider') {
                window.addEventListener('resize', this.debounce(initFunction.bind(this), 250));
            }
        }
    },

    debounce: function (func, wait) {
        let timeout;
        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(), wait);
        };
    },

    commonInitConditions: function(items, width, selector) {
        const mobileWidth = 991;
    
        if (items.length <= 2 || width > mobileWidth) {
            items.forEach(item => {
                item.classList.remove('swiper-slide', 'swiper-slide-active', 'swiper-slide-next', 'swiper-slide-prev');
                item.removeAttribute('style');
            });
            const navigationEl = items[0].closest(selector).querySelector('.slider__navigation');
            items[0].closest('.swiper-wrapper').style.flexWrap = "wrap";
            navigationEl.style.display = "none";
            return true;
        }
        return false;
    },

    initSliderGeneric: function (selector, config) {
        document.querySelectorAll(selector).forEach(sliderContainer => {
            const { listSelector, itemSelector, initConditions, swiperConfig } = config;

            const sliderList = sliderContainer.querySelector(listSelector);
            if (!sliderList.classList.contains('swiper-wrapper')) return;
            
            const items = sliderList.querySelectorAll(itemSelector);
            
            const nextEl = sliderContainer.querySelector(`.slider__next`);
            const prevEl = sliderContainer.querySelector(`.slider__prev`);
            const navigationEl = sliderContainer.querySelector(`.slider__navigation`);

            if (initConditions && initConditions(items, window.innerWidth)) return;

            if (selector !== '.f-news__slider') {
                sliderList.style.gap = "0px";
            }
            sliderList.style.flexWrap = "nowrap";
            sliderList.style.justifyContent = "flex-start";
            navigationEl.style.display = "flex";
            items.forEach(item => item.classList.add('swiper-slide'));

            const swiperInstance = new Swiper(sliderContainer, {
                ...swiperConfig,
                lazy: true,
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                }
            });

            sliderContainer.setAttribute('tabindex', '-1');
            
            // Ajout d'une gestion des touches de clavier pour le conteneur
            sliderContainer.addEventListener('keydown', (event) => {
                if (event.key === "ArrowRight") {
                    swiperInstance.slideNext();
                    const currentSlide = swiperInstance.slides[swiperInstance.activeIndex];
                    const firstLinkInSlide = currentSlide.querySelector('a');
                    if (firstLinkInSlide) firstLinkInSlide.focus();
                }
                if (event.key === "ArrowLeft") {
                    swiperInstance.slidePrev();
                    const currentSlide = swiperInstance.slides[swiperInstance.activeIndex];
                    const firstLinkInSlide = currentSlide.querySelector('a');
                    if (firstLinkInSlide) firstLinkInSlide.focus();
                }
            });
    
            // Gérer les éléments focusables à l'intérieur du slider
            const focusableElems = sliderContainer.querySelectorAll('a, button, input, [tabindex]:not([tabindex="-1"])');
            focusableElems.forEach(elem => {
                elem.addEventListener('focus', () => {
                    const slide = elem.closest('.swiper-slide');
                    if (slide && !slide.classList.contains('swiper-slide-active')) {
                        const slideIndex = Array.from(slide.parentNode.children).indexOf(slide);
                        swiperInstance.slideTo(slideIndex);
                    }
                });
            });
        });
    },


    initSliderNews: function () {
        this.initSliderGeneric('.f-news__slider', {
            listSelector: '.f-news__list',
            itemSelector: '.f-news__list__item',
            initConditions: (items, width) => this.commonInitConditions(items, width, '.f-news__slider'),
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true }
            }
        });
    },

    initSliderDownloads: function () {
        this.initSliderGeneric('.f-downloads__slider', {
            listSelector: '.f-downloads__list',
            itemSelector: '.f-downloads__list__item',
            initConditions: (items, width) => this.commonInitConditions(items, width, '.f-downloads__slider'),
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true },
                breakpoints: {
                    767: {
                        slidesPerView: 2
                    }
                }
            }
        });
    },


    initSliderRubrics: function () {
        this.initSliderGeneric('.f-rubrics__slider', {
            listSelector: '.f-rubrics__list',
            itemSelector: '.f-rubrics__list__item',
            initConditions: (items) => items.length < 5,
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        centeredSlides: false,
                    }
                }
            }
        });
    },

    initSliderMultimedias: function () {
        this.initSliderGeneric('.f-multimedias__slider', {
            listSelector: '.f-multimedias__list',
            itemSelector: '.f-multimedias__card',
            initConditions: (items) => items.length < 3,
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        centeredSlides: false,
                    }
                }
            }
        });
    },

    initSliderResources: function () {
        this.initSliderGeneric('.f-resources__slider', {
            listSelector: '.f-resources__list',
            itemSelector: '.f-resources__list__item',
            initConditions: (items) => items.length < 3,
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 25,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        centeredSlides: false,
                    }
                }
            }
        });
    },
    initSliderToolsbox: function () {
        this.initSliderGeneric('.f-toolsbox__slider', {
            listSelector: '.f-toolsbox__list',
            itemSelector: '.f-toolsbox__list__item',
            initConditions: (items) => items.length < 4,
            swiperConfig: {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 25,
                pagination: {
                    el: '.slider__pagination',
                    clickable: true
                },
                a11y: { enabled: true },
                lazy: { loadPrevNext: true },
                breakpoints: {
                    991: {
                        slidesPerView: 3,
                        centeredSlides: false,
                    }
                }
            }
        });
    }
};

export default Sliders;