'use strict';

class pictoTexte {

    static init() {
        document.fonts.ready.then(() => {
            this.pictos = document.querySelectorAll(".pictoTexte_container");

            if (this.pictos.length > 0) {
                this.pictos.forEach(picto => {
                    picto.classList.remove("pictoTexte_container");
                    let infosClass = picto.className;
                    picto.className = "pictoTexte_container";
                    picto.outerHTML = "<div class='" + infosClass + "'>" + picto.outerHTML + "</div>";
                })

                this.pictos = document.querySelectorAll(".pictoTexte_container");

                window.addEventListener('resize', this.debounce(this.positionne.bind(this), 67));
                this.positionne();
            }
        });
    }

    static debounce(fn, timer) {
        let timeout;
        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(() => fn(), timer);
        };
    }

    static positionne() {
        this.pictos.forEach(picto => {
            if (typeof(picto.imgs) === "undefined") {
                picto.imgs = picto.querySelectorAll(".picto-right");
            }

            let widthPicto = picto.getBoundingClientRect().width;
            picto.imgs.forEach(img => img.style.left = widthPicto + "px")
            picto.classList.add("pictoTexte_container--ready")
        })
    }
};

export default pictoTexte;