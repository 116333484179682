'use strict';

const Anchors = {

    init() {
        this.blocks = document.querySelectorAll('.tpl-summary__wysiwyg');
        this.anchors = document.querySelectorAll('.tpl-summary__anchors-list li');

        if (this.blocks.length > 0 && this.anchors.length > 0 && window.innerWidth > 991) {
            window.addEventListener('scroll', () => this.updateActiveAnchors());
        }
    },
    isAnchorActive(el) {
        const percentElementReachTop = 15;
        const bounding = el.getBoundingClientRect();
        return bounding.top <= (window.innerHeight || document.documentElement.clientHeight) * (percentElementReachTop / 100) && bounding.bottom >= 0;
    },
    updateActiveAnchors() {
        let activeElementIndex = -1;
        this.blocks.forEach((block, index) => {
            if (this.isAnchorActive(block)) {
                activeElementIndex = index;
            }
        });

        this.anchors.forEach((anchor, index) => {
            if (index === activeElementIndex) {
                anchor.classList.add('active');
                anchor.setAttribute("aria-current", "true")
            } else {
                anchor.classList.remove('active');
                anchor.removeAttribute("aria-current")
            }
        });
    }

};

export default Anchors;