'use strict';

const Videos = {
    player: null,
    isListenerAdded: false,

    init() {
        this.cacheDOMElements();
        if (this.cardsWithVideo.length > 0) {
            this.initPopupvideo();
            this.toggleTranscription();
        }
    },

    cacheDOMElements() {
        this.popup = document.querySelector('.f-multimedias__popup');
        this.iframe = document.querySelector('.f-multimedias__video');
        this.initIframe();
        this.playerVisual = this.popup?.querySelector('.f-multimedias__card-visual');
        this.image = this.playerVisual?.querySelector('img');
        this.transcriptionContainer = this.popup?.querySelector('.f-multimedias__popup__transcription');
        this.cardPopupTop = this.popup?.querySelector('.f-multimedias__card-top');
        this.cardPopupBottom = this.popup?.querySelector('.f-multimedias__card-bottom');
        this.closeBtn = this.popup?.querySelector('.close-btn');
        this.cardsWithVideo = document.querySelectorAll('.f-multimedias__card[data-video-id]');
        this.btnPlayPopup = this.popup?.querySelector('.btn-play');
    },

    initIframe() {
        if (this.iframe) {
            this.iframe.innerHTML = "<div class='f-multimedias__video__children'></div>";
            this.iframeTarget = this.iframe.querySelector(".f-multimedias__video__children");
            this.player = null;
        }
    },

    initPopupvideo() {
        if (!this.closeBtn || !this.popup || !this.iframe) return;

        this.popup.addEventListener('keydown', this.handleKeyPress.bind(this));
        this.popup.addEventListener('keydown', this.keepFocusInsidePopup.bind(this));
        this.popup.addEventListener('click', this.handleOutsideClick.bind(this));
        this.cardsWithVideo.forEach(card => {
            card.querySelector('.btn-play')?.addEventListener('click', this.handleCardClick.bind(this))
        });
        this.closeBtn.addEventListener('click', this.handleCloseBtnClick.bind(this));
    },

    keepFocusInsidePopup(event) {
        const focusableEls = this.popup.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"]), input, select, textarea');
        const firstFocusableEl = focusableEls[0];
        const lastFocusableEl = focusableEls[focusableEls.length - 1];

        if (event.key === 'Tab' || event.keyCode === 9) {
            // Si Maj + Tab est pressé, déplacez le focus sur le dernier élément focusable
            if (event.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    event.preventDefault();
                }
            }
            // Sinon, déplace le focus sur le premier élément focusable
            else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    event.preventDefault();
                }
            }
        }
    },

    handleKeyPress(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
            this.handleCloseBtnClick();
        }
    },

    handleOutsideClick(event) {
        if (event.target === this.popup) {
            this.handleCloseBtnClick();
        }
    },

    handleCardClick(event) {
        // Vérifier le consentement pour YouTube via tarteaucitron
        if (tarteaucitron.state.youtubeapi === true) {
            this.updatePopupData(event.currentTarget.parentNode.parentNode);
            this.openPopup();
            this.loadVideoPlayer();
        } else {
            alert('Veuillez accepter les cookies YouTube dans les paramétrages des cookies (bouton "Cookies" ou "Gestion des cookies" en bas de page) pour pouvoir lire la vidéo.');
        }
    },

    loadVideoPlayer() {
        if (typeof YT !== 'undefined' && YT.Player) {
            if (!this.player) {
                this.player = new YT.Player(this.iframeTarget, {
                    videoId: this.iframe.dataset.youtubeId,
                    playerVars: {
                        'playsinline': 1
                    },
                    events: {
                        'onReady': () => {
                            if (!this.isListenerAdded && this.btnPlayPopup) {
                                this.btnPlayPopup.addEventListener('click', this.playVideo.bind(this));
                                this.isListenerAdded = true;
                            }
                            this.iframe.focus();
                        }
                    }
                });
            } else {
                if (!this.isListenerAdded && this.btnPlayPopup) {
                    this.btnPlayPopup.addEventListener('click', this.playVideo.bind(this));
                    this.isListenerAdded = true;
                }
            }
        }
    },

    updatePopupData(card) {
        if (!card) return;

        const videoId = card.getAttribute('data-video-id');
        if (card.getAttribute('data-video-transcription') && this.transcriptionContainer) {
            this.transcriptionContainer.querySelector('.transcription__text').innerHTML = card.getAttribute('data-video-transcription')
        }

        const cardImageElem = card.querySelector('.f-multimedias__card-visual img');
        const cardImage = cardImageElem ? cardImageElem.getAttribute('src') : '';
        if (this.image) this.image.setAttribute('src', cardImage);

        this.updateCardContent(card, this.cardPopupTop, '.f-multimedias__card-top');
        this.updateCardContent(card, this.cardPopupBottom, '.f-multimedias__card-bottom');

        if (this.iframe) {
            if (tarteaucitron.state.youtubeapi === true) {
                this.iframe.dataset.youtubeId = videoId
            }
        }
        this.updateCardTitle()
    },
    updateCardTitle() {
        const cardTitle = this.cardPopupBottom.querySelector('.f-multimedias__card-title');
        if (cardTitle && cardTitle.tagName === 'H3') {
            const newTitle = document.createElement('h1');
            newTitle.innerHTML = cardTitle.innerHTML;
            newTitle.className = cardTitle.className;
            this.cardPopupBottom.replaceChild(newTitle, cardTitle);
        }
    },

    updateCardContent(card, popupElement, querySelector) {
        const cardElem = card.querySelector(querySelector);
        const cardContent = cardElem ? cardElem.innerHTML : "";
        if (popupElement) popupElement.innerHTML = cardContent;
    },

    openPopup() {
        this.popup.classList.add('open');
        document.body.style.overflow = 'hidden';
        this.popup.setAttribute('aria-hidden', 'false');
        setTimeout(() => this.closeBtn.focus(), 500);
    },

    handleCloseBtnClick() {
        this.player?.stopVideo();
        this.popup.classList.remove('open');
        document.body.style.overflow = 'auto';
        this.iframe.style.zIndex = '0';
        this.iframe.style.display = 'none';
        this.transcriptionContainer.classList.remove('open');
        this.transcriptionContainer.setAttribute('aria-hidden', 'true');
        this.initIframe();
        this.transcriptionContainer.querySelector('p').textContent = '';
        this.popup.setAttribute('aria-hidden', 'true');
    },

    playVideo() {
        this.iframe.style.zIndex = '3';
        console.log('playviedo')
        this.iframe.style.display = 'block';
        this.iframe.setAttribute('tabindex', '0');
        if (this.player?.playVideo) {
            this.player.playVideo();
        }
    },

    toggleTranscription() {
        const btnReadMore = document.querySelector('.btn-read-more');
        if (btnReadMore && this.transcriptionContainer) {
            btnReadMore.addEventListener('click', () => {
                const isOpen = this.transcriptionContainer.classList.contains('open');
                this.transcriptionContainer.classList.toggle('open', !isOpen);
                this.transcriptionContainer.setAttribute('aria-hidden', isOpen);
                btnReadMore.setAttribute('aria-expanded', !isOpen);
                btnReadMore.classList.toggle("open");
                this.transcriptionContainer.scrollTop = 0
            });
        }
    }
};

export default Videos;