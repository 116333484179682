'use strict';

const Pagination = {

    init() {
        this.pagination = document.querySelectorAll('.tpl-list__pagination');
        if (this.pagination.length > 0) {
            // Pagination generé via Wordpress. accesssibilité : deplacer les elements pour que le totalPages et pageNumbers se suivent 
            this.pagination.forEach(paginate => {
                const totalPages = paginate.querySelector('.total-pages');
                const pageNumbers = paginate.querySelector('.page-numbers.current');
                if (totalPages && pageNumbers) {
                    const nextSibling = pageNumbers.nextSibling;
                    paginate.insertBefore(totalPages, nextSibling);
                }
            });
        }
    },
};

export default Pagination;