'use strict';

const DropDown = {
    init() {
        const dropdowns = document.querySelectorAll('.js-filter-list');
        const btn = document.querySelector('.js-open-btn');
        if (dropdowns.length > 0) {
            dropdowns.forEach(dropdown => {
                this.setupDropdown(dropdown);
            });
        }
        if (btn) {
            this.dropdownSummary();
        }
    },
    setupDropdown(filtersList) {

        const filtersBtn = filtersList.querySelector('.js-btn-list');
        const links = filtersList.querySelectorAll('.js-filters a');

        if (!filtersBtn || links.length === 0) return;

        // Initialiser les liens comme non navigables dès le départ
        links.forEach(link => {
            link.style.visibility = 'hidden';
        });

        const updateLinksAccessibility = (isOpen) => {
            if (isOpen) {
                links.forEach(link => {
                    link.style.visibility = 'visible';
                });
            } else {
                const onTransitionEnd = () => {
                    links.forEach(link => {
                        link.style.visibility = 'hidden';
                    });
                    filtersList.removeEventListener('transitionend', onTransitionEnd);
                };
                filtersList.addEventListener('transitionend', onTransitionEnd);
            }
        };

        const handleEscKey = (event) => {
            if (event.key === 'Escape' && filtersList.classList.contains('open')) {
                filtersList.classList.remove('open');
                updateLinksAccessibility(false);
                document.removeEventListener('keydown', handleEscKey);
                filtersBtn.setAttribute('aria-expanded', 'false');
            }
        };
        const handleFocusOut = (event) => {
            if (!filtersList.contains(event.relatedTarget)) {
                filtersList.classList.remove('open');
                updateLinksAccessibility(false);
                document.removeEventListener('keydown', handleEscKey);
                filtersList.removeEventListener('focusout', handleFocusOut);
                filtersBtn.setAttribute('aria-expanded', 'false');
            }
        };
        // Toggle open list 
        filtersBtn.addEventListener('click', () => {
            const isOpening = !filtersList.classList.contains('open');
            filtersList.classList.toggle('open');
            updateLinksAccessibility(isOpening);
            filtersBtn.setAttribute('aria-expanded', isOpening ? 'true' : 'false');
            if (isOpening) {
                document.addEventListener('keydown', handleEscKey);
                filtersList.addEventListener('focusout', handleFocusOut);
            } else {
                document.removeEventListener('keydown', handleEscKey);
            }
        });
    },
    dropdownSummary() {
        const isMobile = window.innerWidth < 991;
        const btn = document.querySelector('.js-open-btn');
        const dropDownList = document.querySelector('.tpl-summary__anchors');

        const toggleDropdown = (state) => {
            btn.setAttribute('aria-expanded', state);
            dropDownList.classList.toggle('open', state);
        };

        if (dropDownList && isMobile) {
            btn.setAttribute('role', 'button');
            btn.setAttribute('aria-expanded', 'false');
            btn.addEventListener('click', () => {
                const currentState = btn.getAttribute('aria-expanded') === 'true';
                toggleDropdown(!currentState);
            })

            dropDownList.addEventListener('focusin', () => {
                toggleDropdown(true);

            });

            dropDownList.addEventListener('focusout', (event) => {
                if (!dropDownList.contains(event.relatedTarget)) {
                    toggleDropdown(false);
                }
            });
        } else {
            btn.removeAttribute('role');
            btn.removeAttribute('aria-expanded');
        }
    }
};

export default DropDown;