'use strict';

const Tracking = {
    init() {

        const trackEvent = (category, action, url) => {
            if (window.dataLayer) {
                window.dataLayer.push({
                    'event': 'customEvent',
                    'eventCategory': category,
                    'eventAction': action,
                    'urlRedirection': url
                });
            }
        };

        document.body.addEventListener('click', (e) => {
            const btn = e.target.closest('.js-btn-tracking, .js-download-tracking, .js-tool-tracking, .js-playVideo-tracking, .js-infography-tracking, .js-footerBtn-tracking, .js-resource-tracking');

            if (!btn) return;

            if (btn.classList.contains('js-btn-tracking')) {
                trackEvent('Button Click', `button - ${btn.textContent}`, btn.getAttribute('href'));
            } else if (btn.classList.contains('js-download-tracking')) {
                const btnText = btn.parentElement.parentElement.parentElement.querySelector('.download__card-title').textContent;
                trackEvent('Download Click', `download - ${btnText}`, btn.getAttribute('href'));
            } else if (btn.classList.contains('js-tool-tracking')) {
                const cardText = btn.querySelector('.tool-title').textContent;
                trackEvent('Button Click', `button - ${cardText}`, btn.getAttribute('href'));
            } else if (btn.classList.contains('js-resource-tracking')) {
                const cardText = btn.parentElement.querySelector('.resource__card__title').textContent;
                trackEvent('Button Click', `article - ${cardText}`, btn.getAttribute('href'));
            } else if (btn.classList.contains('js-playVideo-tracking')) {
                const videoTitle = btn.parentElement.parentElement.querySelector('.f-multimedias__card-title').textContent;
                trackEvent('Play Video', `video - ${videoTitle}`, 'no redirection')
            } else if (btn.classList.contains('js-infography-tracking')) {
                const infographyTitle = btn.querySelector('.f-multimedias__card-title').textContent;
                trackEvent('Infography Click', `infography - ${infographyTitle}`, btn.getAttribute('href'))
            } else if (btn.classList.contains('js-footerBtn-tracking')) {
                trackEvent('Button Click', `footer - ${btn.textContent}`, btn.getAttribute('href'));
            }

        });

        const headerMenu = document.querySelector('.c-header__nav__bottom');
        if (headerMenu) {
            const headerMenuItems = headerMenu.querySelectorAll('.menu-item a');
            if (headerMenuItems.length > 0) {
                headerMenuItems.forEach(item => {
                    item.addEventListener('click', () => {
                        trackEvent('Button Click', `header - ${item.textContent}`, item.getAttribute('href'));
                    })
                })
            }
        }
    },
};

export default Tracking;