'use strict';

const Podcast = {
    init() {
        if (document.querySelectorAll(".f-podcast").length > 0) {
            document.querySelectorAll(".f-podcast").forEach(podcast => {
                const mobileHeight = podcast.getAttribute('data-mobileheight');
                const desktopHeight = podcast.getAttribute('data-desktopheight');
                const iframe = podcast.getElementsByTagName('iframe')[0];
                if (iframe) {
                    if (mobileHeight && window.innerWidth < 991) {
                        iframe.style.height = `${mobileHeight}px`
                    }
                    if (desktopHeight && window.innerWidth > 991) {
                        iframe.style.height = `${desktopHeight}px`
                    }
                }

                const transcription = podcast.querySelector(".transcription__text");
                const btn_transcription = podcast.querySelector(".transcription__button")

                if (transcription && btn_transcription) {
                    btn_transcription.addEventListener("click", () => {
                        const isOpen = btn_transcription.classList.toggle("open");
                        transcription.classList.toggle("open");
                        btn_transcription.setAttribute('aria-expanded', isOpen);
                        transcription.setAttribute('aria-hidden', !isOpen);
                        if (!isOpen) {
                            transcription.setAttribute('tabindex', '-1');
                        } else {
                            transcription.setAttribute('tabindex', '0');
                        }
                    });
                }
            })
        }
    },
};

export default Podcast;