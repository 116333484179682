
import Sliders from "./components/sliders";
import Videos from "./components/videos";
import BackToTop from "./components/backToTop";
import DropDown from "./components/dropDown";
import BurgerMenu from "./components/burgerMenu";
import Quizz from "./components/quizz";
import Iframe from "./components/iframe";
import pictoTexte from "./components/pictoTexte";
import Tracking from "./components/tracking";
import Anchors from "./components/anchors";
import Pagination from "./components/pagination";
import Podcast from "./components/podcast";
import Wysiwyg from "./components/wysiwyg";

const main = {
    init: function () {
        Sliders.init();
        Videos.init();
        BackToTop.init();
        DropDown.init();
        BurgerMenu.init();
        Quizz.init();
        Iframe.init();
        Podcast.init();
        Tracking.init();
        pictoTexte.init();
        Anchors.init();
        Pagination.init();
        Wysiwyg.init();
    }
}

export default main