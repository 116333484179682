'use strict';

const Wysiwyg = {

    init() {

        let parentTag = ["h1", "h2", "h3", "h4", "h5", "h6"];
        let tags = ["span", "strong"];

        let selectors = [];
        parentTag.forEach(h => {
            tags.forEach(tag => {
                selectors.push(".c-wysiwyg " + h + " " + tag)
            })
        })

        Wysiwyg.spans = document.querySelectorAll(selectors.join(","));

        Wysiwyg.spans.forEach(item => {
            let parent = item.closest(parentTag.join(","));
            if (parent) {
                let bigparent = parent.parentElement;

                if (bigparent.children[bigparent.children.length - 1] !== parent) {
                    parent.classList.add("moreMargin");
                } else {
                    let sectionparent = item.closest("section");
                    if (sectionparent && sectionparent.nextElementSibling && sectionparent.nextElementSibling.classList.contains("f-bisou")) {
                        parent.classList.add("moreMargin");
                    }
                }
            }
        })
    },
};

export default Wysiwyg;