'use strict';

const Iframe = {

    init() {
        if (document.querySelectorAll(".js-iframe").length > 0) {
            document.querySelectorAll('.js-iframe').forEach(iframe => {
                iframe.addEventListener('load', function () {
                    iFrameResize({
                        checkOrigin: false,
                        heightCalculationMethod: 'bodyScroll',
                        maxWidth: 800,
                    }, '.js-iframe')
                })
            })
        }
    },
};

export default Iframe;