'use strict';

const BurgerMenu = {

    init() {
        const header = document.querySelector('.c-header');
        if (header) {

            const burgerBtn = header.querySelector('.js-burger-btn');
            const dropdownsHeader = header.querySelectorAll('.js-btn-list');
            const btnCloseSubMenu = header.querySelector('.js-close-submenu');
            const btnCloseMenu = header.querySelector('.js-close-menu');

            this.itemHasChildrenAccessibility(header);

            let isUsingKeyboard = false;
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Tab') {
                    isUsingKeyboard = true;
                }
            });

            document.addEventListener('mousedown', () => {
                isUsingKeyboard = false;
            });


            if (burgerBtn) {

                burgerBtn.addEventListener('click', () => {

                    header.classList.toggle('open');
                    burgerBtn.setAttribute('aria-expanded', header.classList.contains('open'));

                    if (header.classList.contains('submenu-open')) {
                        header.classList.remove('submenu-open')
                    }

                });

                dropdownsHeader.forEach(dropdown => {
                    dropdown.addEventListener('click', () => {

                        if (window.innerWidth < 991) {
                            header.classList.toggle('submenu-open')
                        }
                    })
                });

                btnCloseSubMenu?.addEventListener('click', () => {
                    header.classList.remove('submenu-open')
                });

                btnCloseMenu?.addEventListener('click', () => {
                    header.classList.remove('submenu-open')
                    header.classList.remove('open')
                });
            }

            // Condition pour fermer le menu si focusOut au clavier

            header.addEventListener('focusout', (e) => {
                if (window.innerWidth < 991 && isUsingKeyboard) {
                    const relatedTarget = e.relatedTarget;
                    if (!header.contains(relatedTarget) && e.target.closest('.c-header')) {
                        if (header.classList.contains('open')) {
                            burgerBtn.focus()
                        }
                        header.classList.remove('open');
                        burgerBtn.setAttribute('aria-expanded', 'false');
                    }
                }
            });
        }
    },
    itemHasChildrenAccessibility(header) {
        const menuItems = header.querySelectorAll('.menu-item-has-children');

        if (menuItems.length > 0) {
            menuItems.forEach(item => {

                if (window.innerWidth < 991) {
                    item.querySelector('a').setAttribute('href', '#');
                }

                const anchor = item.querySelector('a');
                anchor.setAttribute('aria-expanded', 'false');

                // Ajouter un écouteur pour la touche Échap pour fermer le menu deplié au hover
                document.addEventListener('keydown', (e) => {
                    if (e.key === 'Escape') {
                        item.classList.add('no-hover');
                    }
                    if (e.key === 'Tab') {
                        item.classList.remove('no-hover');
                    }

                });

                // Retirer la classe no-hover lorsque la souris entre sur l'élément et permettre l'animation au hover
                item.addEventListener('mouseenter', () => {
                    item.classList.remove('no-hover');
                });



                const toggleAriaExpanded = (value, event) => {
                    if (anchor.getAttribute('aria-expanded') !== value) {
                        if (event.relatedTarget === null || !item.contains(event.relatedTarget)) {
                            anchor.setAttribute('aria-expanded', value);
                        }
                    }
                };

                item.addEventListener('focusin', (event) => toggleAriaExpanded('true', event));
                item.addEventListener('focusout', (event) => toggleAriaExpanded('false', event));
                item.addEventListener('mouseenter', (event) => toggleAriaExpanded('true', event));
                item.addEventListener('mouseleave', (event) => toggleAriaExpanded('false', event));
            });
        }
    },
};

export default BurgerMenu;