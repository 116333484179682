'use strict';

const Quizz = {
    init() {
        this.parentQuestion = null;
        this.quizzContainer = document.querySelector('.f-quizz__container');
        this.resetBtns = document.querySelectorAll('.reset-btn');
        this.selectedAnswer = null;
        this.breadcrumbContainer = document.querySelector('.f-quizz__breadcrumb');
        if (this.breadcrumbContainer) {
            this.initBreadcrumb();
        }
        if (this.quizzContainer) {
            this.quizzContainer.addEventListener('click', this.handleQuizzContainerClick.bind(this));
            this.resetBtns.forEach(btn => btn.addEventListener('click', this.resetQuizz.bind(this)));
        }
    },

    initBreadcrumb() {
        // Initialiser ou réinitialiser le fil d'Ariane
        this.breadcrumbContainer.innerHTML = '';
        this.addBreadcrumbItem(1, "question1");
    },

    addBreadcrumbItem(step, nextStepId) {
        const li = document.createElement('li');
        li.textContent = step === 'Resultat' ? 'Résultat' : `Étape ${step}`
        li.id = `breadcrumb-step-${step}`;
        li.addEventListener('click', () => this.handleBreadcrumbClick(step, nextStepId));
        this.breadcrumbContainer.appendChild(li);
    },

    handleBreadcrumbClick(stepNumber, questionId) {
        // Trouvez l'ID de l'étape correspondant au numéro de l'étape
        const stepId = questionId;

        // Mettez à jour la visibilité des étapes et la position du fil d'Ariane
        this.updateStepsVisibility(stepId);
        this.updateBreadcrumbOnBack(stepNumber);

        // Gérer l'affichage du bouton "précédent"
        this.quizzContainer.classList.toggle('visible-btn', stepNumber !== 1);

        // Gérer l'affichage du bouton "suivant"
        if (stepId === 'question1' || this.quizzContainer.querySelector('.f-quizz__answer-last.hidden')) {
            this.quizzContainer.classList.remove('finished');
        } else {
            this.quizzContainer.classList.add('finished');
        }
    },

    updateBreadcrumbOnBack(currentStep) {
        while (this.breadcrumbContainer.children.length > currentStep) {
            this.breadcrumbContainer.removeChild(this.breadcrumbContainer.lastChild);
        }
    },

    updateBreadcrumb(stepNumber, nextStepId) {
        if (this.breadcrumbContainer.children.length < stepNumber) {
            this.addBreadcrumbItem(stepNumber, nextStepId);
        } else {
            this.breadcrumbContainer.children[stepNumber - 1].textContent = `Étape ${stepNumber}`;
        }
    },

    handleQuizzContainerClick(event) {
        if (event.target.classList.contains('f-quizz__answer')) {
            this.selectedAnswer = event.target;
            this.quizzContainer.classList.remove('error')
            document.querySelectorAll('.f-quizz__answer').forEach(answer => {
                answer.classList.remove('selected');
                this.selectedAnswer.classList.add('selected');
            })
        }
        if (event.target.classList.contains('js-next-btn')) {
            if (this.selectedAnswer !== null) {
                this.handleAnswerClick(this.selectedAnswer);
            } else {
                this.quizzContainer.classList.add('error')
            }
        }
        if (event.target.classList.contains('js-back-btn') && this.parentQuestion !== null) {
            this.handleBackClick();
        }
    },

    handleAnswerClick(button) {
        const nextStepId = button.getAttribute('data-next');
        this.updateStepsVisibility(nextStepId);
        this.quizzContainer.classList.toggle('visible-btn', this.parentQuestion !== null);
        const stepNumber = this.getStepNumber(nextStepId);
        // console.log(nextStepId)
        this.updateBreadcrumb(stepNumber, nextStepId);
        this.selectedAnswer = null;
        button.classList.remove('selected');
        this.checkIfEndOfQuizz();
    },

    checkIfEndOfQuizz() {
        let isEnd = false;
        document.querySelectorAll('.f-quizz__answer-last').forEach(lastAnswer => {
            if (!lastAnswer.classList.contains('hidden')) {
                isEnd = true;
            }
        });
        if (isEnd) {
            this.quizzContainer.classList.add('finished');
            this.addBreadcrumbItem('Resultat');
        } else {
            this.quizzContainer.classList.remove('finished');
        }
    },

    getStepNumber(stepId) {
        switch (stepId) {
            case 'question1': return 1;
            case 'question2': return 2;
            case 'question3': return 3;
            case 'question4': return 3;
            default: return 1;
        }
    },

    handleBackClick() {
        this.updateStepsVisibility(this.parentQuestion);
        this.quizzContainer.classList.toggle('visible-btn', this.parentQuestion !== null);
        this.selectedAnswer = null;
        this.quizzContainer.classList.remove('error')
        // Supprimer le dernier élément du fil d'Ariane
        if (this.breadcrumbContainer.children.length > 1) {
            this.breadcrumbContainer.removeChild(this.breadcrumbContainer.lastChild);
        }
        this.checkIfEndOfQuizz();
    },

    updateStepsVisibility(visibleStepId) {
        const allsteps = this.quizzContainer.querySelectorAll('.js-step-quizz');
        allsteps.forEach(step => {
            const isVisible = step.getAttribute("id") === visibleStepId;
            step.classList.toggle('hidden', !isVisible);
            if (isVisible) {
                this.parentQuestion = step.getAttribute('data-parent');
                setTimeout(() => {
                    step.querySelector('.f-quizz__answers')?.focus();
                }, 0);
            }
        });
    },

    resetQuizz(event) {
        this.quizzContainer.classList.remove('visible-btn');
        const parentQuizzContainer = event.currentTarget.closest('.f-quizz');
        this.updateStepsVisibility("question1");
        this.scrollToContainer(parentQuizzContainer);
        this.initBreadcrumb();
        this.checkIfEndOfQuizz();
    },

    scrollToContainer(container) {
        const containerPosition = container.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({
            top: containerPosition,
            behavior: 'smooth'
        });
    }
};

export default Quizz;